import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './Components/Bars/Sidebar';
import Navbar from './Components/Bars/Navbar';
import Home from './Components/Home/HomePage';
import Heading from './Components/Heading';
import DHeading from './Components/TileCalculation/DHeading';
import  InHeading from './Components/Invoice/InHeading'
function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <Sidebar />
        <Routes>
          <Route path="/" element={<Home/>} /> 
          <Route path="/expense-entry/*" element={<Heading />} />
          <Route path="/designtool/*" element={<DHeading/>}/>
          <Route path='/invoice-bill/*' element={<InHeading/>}/>
        </Routes>
      </Router>
    </div>
  );
}
export default App;
