import React, { useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import './InHeading.css'
import  Invoice from '../Invoice/Invoice'
import EditInvoice from '../Invoice/EditInvoice';
import History from './History';
import Database from './Database';
import AddInput from './AddInput'
const Heading = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    const handleLinkClick = (path) => {
        setActiveLink(path);
    };
    console.log("Current Path:", location.pathname); // Check the current path
    return (
        <body className=" bg-[#FAF6ED]">
            <div className="topbar-title ml-40">
                <h2 className="mb-1">
                    <Link
                        className={`link ${activeLink === '/invoice-bill/invoice' ? 'active' : ''}`}
                        to="/invoice-bill/invoice"
                        onClick={() => handleLinkClick('/invoice-bill/invoice')}
                    >
                       Create Invoice
                    </Link>
                </h2>
                <h2>
                    <Link
                        className={`link ${activeLink === '/invoice-bill/editinvoice' ? 'active' : ''}`}
                        to="/invoice-bill/editinvoice"
                        onClick={() => handleLinkClick('/invoice-bill/editinvoice')}
                    >
                        Edit Invoice
                    </Link>
                </h2>
                <h2>
                    <Link
                        className={`link ${activeLink === '/invoice-bill/history' ? 'active' : ''}`}
                        to="/invoice-bill/history"
                        onClick={() => handleLinkClick('/invoice-bill/history')}
                    >
                        History
                    </Link>
                </h2>
                <h2>
                    <Link
                        className={`link ${activeLink === '/invoice-bill/database' ? 'active' : ''}`}
                        to="/invoice-bill/database"
                        onClick={() => handleLinkClick('/invoice-bill/database')}
                    >
                        Database
                    </Link>
                </h2>
                <h2>
                    <Link
                        className={`link ${activeLink === '/invoice-bill/addinput' ? 'active' : ''}`}
                        to="/invoice-bill/addinput"
                        onClick={() => handleLinkClick('/invoice-bill/addinput')}
                    >
                        Add Input
                    </Link>
                </h2>
            </div>

            <Routes>
                <Route path="invoice" element={<Invoice/>} />  {/* Form Component */}
                <Route path='editinvoice' element={<EditInvoice/>} />  {/* Table View Component */}
                <Route path='history' element={<History/>}  />  {/* Database Component */}
                <Route path='database' element={<Database/>}  />
                <Route path='addinput' element={<AddInput/>}  />
            </Routes>
        </body>
    );
}
export default Heading;
